




































































































import { Vue, Prop, PropSync, Component } from 'vue-property-decorator';

import { TableHeader } from '@/components/layout/models/table.d';
import { TableOptions } from '@/models/form';

import config from '@/config';
import { TemplateOperation } from '@/models/library-maintenance';

@Component({

})
export default class TemplateOperationTable extends Vue {
  @Prop({ default: () => ({}) }) options!: TableOptions;

  @Prop({ default: false }) loading!: boolean;

  @Prop() libraryId!: string;

  @Prop() templateId!: string;

  headers: TableHeader[] = [];

  @Prop({ default: 0 }) serverItemsLength!: number;

  @PropSync('itemsPerPage', { default: () => config.defaultPageSize }) syncedItemsPerPage!: number;

  @PropSync('page', { default: 1}) syncedPage!: number;

  get numberOfPages() {
    if (this.syncedItemsPerPage === 0) return 1;
    return Math.max(1, Math.ceil(this.serverItemsLength / this.syncedItemsPerPage));
  }

  getProgress(item: TemplateOperation) {
    return item['rules-to-process'] == 0 ? 100 : 100 * item['rules-processed'] / item['rules-to-process'];
  }

  mounted() {
    this.headers = [
      {
        sortable: false,
        text: '',
        value: 'refresh-column',
        width: '1%',
      },
      {
        sortable: false,
        text: 'Date Started',
        value: 'date-started'
      },
      {
        sortable: false,
        text: 'Date Completed',
        value: 'date-finished'
      },
      {
        sortable: false,
        text: 'Progress',
        value: 'progress',
        cellClass: 'text-center'
      },
      {
        sortable: false,
        text: 'Faults',
        value: 'faults'
      }
    ]
  }
}

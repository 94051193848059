
















































import {
  Component, Vue, Inject, Watch
} from 'vue-property-decorator';
import Card from '@/components/material/Card.vue';
import { SnackbarOptions } from '@/models/form';
import EcSnackBar from 'common-components/src/components/form/ec-snack-bar.vue';
import TemplateOperationService from '@/services/template-operation-service';
import { Template, TemplateOperation } from '@/models/library-maintenance';
import TemplateOperationTable from '@/components/library-maintenance/template-operation-table.vue';

import { getModule } from 'vuex-module-decorators';
import LocalState from '@/store/modules/local-state-module';
import TemplateService from '@/services/template-service';
import axios, { CancelTokenSource } from 'axios';
import { backOr } from "@/router";

const localState = getModule(LocalState);

@Component({
  components: {
    Card,
    EcSnackBar,
    TemplateOperationTable
  }
})
export default class TemplateOperationsView extends Vue {
  @Inject() TemplateOperationService!: TemplateOperationService;

  @Inject() TemplateService!: TemplateService;

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  libraryId = '';

  templateId = '';

  template: Template = {} as Template;

  templateTitle = '';

  loading = false;

  items: TemplateOperation[] = [];

  page = 1;

  size = localState.defaultPageSize;

  totalItems = 100;

  cancellationToken!: CancelTokenSource;

  @Watch('$route', { immediate: true })
  async onRouteChange() {
    const {
      page = 1,
      size = localState.defaultPageSize
    } = this.$route.query || {};

    this.libraryId = this.$route.params.libraryId;
    this.templateId = this.$route.params.templateId;

    try {
      const template = await this.TemplateService.readSingle(this.libraryId, this.templateId);
      this.template = template;
      this.templateTitle = `Template Operations for ${this.template.name}`;
    }
    catch (e) {

    }

    this.page = +page;
    this.size = +size;

    await this.loadPage();
  }

  async loadPage() {
    if (!this.libraryId || !this.templateId) return;

    this.loading = true;

    const pagination = {
      page: this.page,
      size: this.size
    };

    try {
      if (this.cancellationToken)
         this.cancellationToken.cancel('Reloaded list');
      this.cancellationToken = axios.CancelToken.source();
      
      const list = await this.TemplateOperationService.listTemplateOperations(
        this.libraryId,
        this.templateId,
        pagination,
        this.cancellationToken.token
      );

      this.size = list.page.size;
      this.page = list.page.number;
      this.totalItems = list.page.totalElements;
      this.items = list._embedded.operations;

      localState.setDefaultPageSize(this.size);

      this.refreshOngoingOperation();
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error as string);
      }
    } finally {
      this.loading = false;
    }
  }

  async requestCascadeUpdate() {
    try {
      await this.TemplateOperationService.requestCascadeUpdate(
        this.template
      );

      await this.loadPage();
    } catch(error) {
    }
  }

  back() {
    backOr({ name: 'template', params: { libraryId: this.libraryId, templateId: this.templateId} });
  }

  get buttonTitle() {
    return `Update ${this.template['number-of-rules']} ${this.template['number-of-rules'] != 1 ? 'Rules' : 'Rule'}`;
  }

  private get ongoingOperation() {
    const index = this.items && this.items.findIndex((x) => !x['date-finished']);

    return index >= 0 ? { index, item: this.items[index] } : null;
  }

  reloadTimer = 0;

  private async refreshOngoingOperation() {
    const ongoing = this.ongoingOperation;
    if (!ongoing) {
      if (this.reloadTimer) {
        window.clearTimeout(this.reloadTimer);
      }
      return;
    }

    const remote = await this.TemplateOperationService.readSingle(
      this.libraryId,
      this.templateId,
      ongoing.item.id
    );
    this.items.splice(ongoing.index, 1, remote);

    this.reloadTimer = window.setTimeout(this.refreshOngoingOperation, 1000 /* 1 second */);
  }
}

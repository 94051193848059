var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"item-key":"id","page":_vm.syncedPage,"items-per-page":_vm.syncedItemsPerPage,"server-items-length":_vm.serverItemsLength,"hide-default-footer":"","show-expand":""},on:{"update:page":function($event){_vm.syncedPage=$event}},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"subheading font-weight-light text-success text--darken-3",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var item = ref.item;
var isExpanded = ref.isExpanded;
return [(item.failures.length)?_c('v-icon',{class:{
        'v-data-table__expand-icon': true,
        'v-data-table__expand-icon--active': isExpanded
      },on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v("$expand")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
      var item = ref.item;
      var headers = ref.headers;
return [_c('td',{staticClass:"px-0 operation-failures",attrs:{"colspan":headers.length}},[_c('v-list',{staticClass:"py-0",attrs:{"two-line":""}},[_vm._l((item.failures),function(failure,index){return [_c('v-list-item',{key:index,attrs:{"to":{
              name: 'rule',
              params: {
                libraryId: _vm.libraryId,
                ruleId: failure['rule-id']
              }
            }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(failure['rule-name']))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(failure['error-message']))])],1)],1),(index < item.failures.length - 1)?_c('v-divider',{key:("divider" + index)}):_vm._e()]})],2)],1)]}},{key:"item.date-started",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(item['date-started']))+" ")]}},{key:"item.date-finished",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(item['date-finished']))+" ")]}},{key:"item.progress",fn:function(ref){
            var item = ref.item;
return [(!item['date-finished'])?_c('v-progress-linear',{attrs:{"height":"23","value":_vm.getProgress(item),"query":""}},[_vm._v(" "+_vm._s(item['rules-processed'])+" / "+_vm._s(item['rules-to-process'])+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(item['rules-processed'])+" / "+_vm._s(item['rules-to-process']))])]}},{key:"item.faults",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.failures.length)+" ")]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners),[_c('template',{slot:"header.refresh-column"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"refresh-button",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('reload')}}},on),[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"16","width":"2"}}):_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_vm._v(" Refresh ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }